import "./login.css"

import Header from "../components/header"
import { PDFExport } from "@progress/kendo-react-pdf"
import React from "react"
import firebase from "gatsby-plugin-firebase"
import logo from "../images/logo.jpeg"
import { navigate } from "@reach/router"

class Results extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      answer: "",
      Question1: "",
      Question1Answer: "",
      Question2A: "",
      Question2AAnswer: "",
      Question2B: "",
      Question2BAnswer: "",
      Question2C: "",
      Question2CAnswer: "",
    }

    this.HandleQuestion = this.HandleQuestion.bind(this)
    this.EditQuestion = this.EditQuestion.bind(this)
  }

  componentDidMount() {
    const db = firebase.firestore()

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log(user.email)
        this.setState({ email: user.email })

        // Getting Questions - Start

        var questionRef = db.collection("Questions").doc(`Questions`)
        var getDoc = questionRef
          .get()
          .then(doc => {
            if (!doc.exists) {
              console.log("No such document!")
            } else {
              this.setState({
                Question1: doc.get("Question1"),
                Question2A: doc.get("Question2A"),
                Question2B: doc.get("Question2B"),
                Question2C: doc.get("Question2C"),
              })
            }
          })
          .catch(err => {
            console.log("Error getting document", err)
          })

        // Getting Questions - End

        // Getting Answers - Start

        var answersRef = db.collection("Users").doc(`${this.state.email}`)
        var getDoc = answersRef
          .get()
          .then(doc => {
            if (!doc.exists) {
              console.log("No such document!")
            } else {
              this.setState({
                Question1Answer: doc.get("Question1"),
                Question2AAnswer: doc.get("Question2A"),
                Question2BAnswer: doc.get("Question2B"),
                Question2CAnswer: doc.get("Question2C"),
              })
            }

            // ADD TO NEW COLLECTION FOR NOTIFICATION

            var newTc = db.collection("NewTC").doc(user.uid)

            return newTc
              .set({
                uid: user.uid,
                email: this.state.email,
                Question1: this.state.Question1,
                Question2A: this.state.Question2A,
                Question2B: this.state.Question2B,
                Question2C: this.state.Question2C,
                Answer1: this.state.Question1Answer,
                Answer2A: this.state.Question2AAnswer,
                Answer2B: this.state.Question2BAnswer,
                Answer2C: this.state.Question2CAnswer,
              })
              .then(() => {
                console.log("Document successfully updated!")
              })
              .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error)
              })
          })
          .catch(err => {
            console.log("Error getting document", err)
          })

        // Getting Answers - End
      } else {
        // No user is signed in.
      }
    })
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  HandleQuestion = e => {
    e.preventDefault()
    const db = firebase.firestore()

    var email = this.state.email
    console.log(email)
    var answer = this.state.answer
    var password = this.state.password

    var userRef = db.collection("Users").doc(`${email}`)

    // Set the "capital" field of the city 'DC'
    return userRef
      .update({
        Question1: answer,
      })
      .then(() => {
        this.props.next()
        console.log("Document successfully updated!")
      })
      .catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error)
      })
  }

  EditQuestion() {
    navigate("/")
  }

  exportPDF = () => {
    this.resume.save()
  }

  render() {
    return (
      <div className="AppContainer">
        <Header />

        <div className="ResultButtonCont">
          <div>
            <button className="NavButtonsResult" onClick={this.EditQuestion}>
              Edit Answers
            </button>
          </div>

          <div>
            <button
              className="NavButtonsResult"
              id="download"
              onClick={this.exportPDF}
            >
              Download PDF
            </button>
          </div>
        </div>

        <div className="CardContainer">
          <PDFExport
            margin={{ top: 20, left: 20, right: 20, bottom: 20 }}
            id="pdfexport"
            paperSize={"auto"}
            fileName="YourTransformationConversation.pdf"
            title=""
            subject=""
            keywords=""
            ref={r => (this.resume = r)}
          >
            <div>
              <div className="resultlogocont">
                <img className="resultlogo" src={logo} />
              </div>

              <div className="MainQuestionContainer">
                <div className="Question1Container">
                  <h1 className="QuestionNumberResults">Question1</h1>
                  <h3 className="QuestionTitleResults">
                    {this.state.Question1}
                  </h3>
                  <p>{this.state.Question1Answer}</p>
                </div>
                <div className="Question2AContainer">
                  <h1 className="QuestionNumberResults">Question2A</h1>
                  <h3 className="QuestionTitleResults">
                    {this.state.Question2A}
                  </h3>
                  <p>{this.state.Question2AAnswer}</p>
                </div>
                <div className="Question2BContainer">
                  <h1 className="QuestionNumberResults">Question2B</h1>
                  <h3 className="QuestionTitleResults">
                    {this.state.Question2B}
                  </h3>
                  <p>{this.state.Question2BAnswer}</p>
                </div>
                <div className="Question2CContainer">
                  <h1 className="QuestionNumberResults">Question2C</h1>
                  <h3 className="QuestionTitleResults">
                    {this.state.Question2C}
                  </h3>
                  <p>{this.state.Question2CAnswer}</p>
                </div>
              </div>
            </div>
          </PDFExport>
        </div>
      </div>
    )
  }
}

export default Results
